<template>
  <widget-pie v-if="type == 'pie' || type == 'ring'" ref="vcharts" :type="type" :data="data" :settings="settings" :defaultSettings="defaultSettings" style="width: 100%; height: 100%;" :readonly="readonly" />
  <component v-else :is="'ve-' + (type == 'area' ? 'line' : type)" ref="vcharts" :data="vdata" :settings="vsettings" :extend="extend" height="100%" :events="events" />
</template>

<script>
import wpie from './pie.vue'
import { parseValue } from '../utils/common'

const DEFAULT_COLORS = ['#19d4ae', '#5ab1ef', '#fa6e86', '#ffb980', '#0067a6', '#c4b4e4', '#d87a80', '#9cbbff', '#d9d0c7', '#87a997', '#d49ea2', '#5b4947', '#7ba3a8']

export default {
  name: "widget-vcharts",
  components: {
    'widget-pie': wpie,
  },
  props: {
    type: {
      type: String,
      default: () => { return undefined }
    },
    params: {
      type: Object,
      default: () => { return undefined }
    },
    data: {
      type: Array,
      default: () => { return undefined }
    },
    settings: {
      type: Object,
      default: () => { return undefined }
    },
    defaultSettings: {
      type: Object,
      default: () => { return undefined }
    },
    readonly: {
      type: Boolean,
      default: () => { return true }
    }
  },
  data() {
    return {
      geojsons: {},
      events: {
        click: (e) => {
          const params = this.params ? {...this.params} : {}
          if (this.settings && this.settings.event && this.settings.event.prefix) {
            /*this._lastSelect = e.name
            window.postMessage({
              type: 'bi_param_set',
              key: this.settings.event.prefix + 'name',
              value: e.name
            })*/
            params[this.settings.event.prefix + 'name'] = e.name
            params[this.settings.event.prefix + 'seriesName'] = e.seriesName
          }
          if (this.readonly && this.settings.event.naviUrl) {
            const naviUrl = parseValue(this.settings.event.naviUrl, params, this.$route && this.$route.query)
            this.$emit('message', 'open-url', this.settings.event.naviType, naviUrl, this)
          }
        }
      }
    }
  },
  mounted() {
    this.$refs.vcharts.echarts.getZr().on('click', (e) => {
      if (!e.target) {
        /*if (this.settings && this.settings.event && this.settings.event.prefix && this._lastSelect) {
          this._lastSelect = ''
          window.postMessage({
            type: 'bi_param_set',
            key: this.settings.event.prefix + 'name',
            value: ''
          })
        }*/
      }
    })
  },
  methods: {
    resize() {
      if (this.$refs.vcharts) {
        this.$refs.vcharts.echarts.resize()
      }
    },
    cleanProperties(props) {
      if (typeof props == 'object') {
        for (let key in props) {
          const value = props[key]
          if (value === null || value === undefined || value === '') {
            delete props[key]
          } else if (typeof value == 'object') {
            this.cleanProperties(value)
          }
        }
      }
    }
  },
  computed: {
    extend() {
      const textColor = (this.settings && this.settings.text.color) || (this.defaultSettings && this.defaultSettings.textColor) || '#000'
      const fontSize = (this.settings && this.settings.text.fontSize) || (this.defaultSettings && this.defaultSettings.textFontSize) || 12

      const legend = {
        textStyle: {
          color: textColor,
          fontSize: fontSize
        }
      }
      if (this.settings && this.settings.legend) {
        legend.show = this.settings.legend.show
        legend.orient = this.settings.legend.orient

        if (this.settings.legend.align == 'left') {
          if (this.settings.legend.alignOffset) {
            legend.left = this.settings.legend.alignOffset
          } else {
            legend.left = 'left'
          }
        } else if (this.settings.legend.align == 'right') {
          if (this.settings.legend.alignOffset) {
            legend.right = this.settings.legend.alignOffset
          } else {
            legend.left = 'right'
          }
        } else {
          legend.left = this.settings.legend.align
        }

        if (this.settings.legend.valign == 'top') {
          if (this.settings.legend.valignOffset) {
            legend.top = this.settings.legend.valignOffset
          } else {
            legend.top = 'top'
          }
        } else if (this.settings.legend.valign == 'bottom') {
          if (this.settings.legend.valignOffset) {
            legend.bottom = this.settings.legend.valignOffset
          } else {
            legend.top = 'bottom'
          }
        } else {
          legend.top = this.settings.legend.valign
        }
      }

      let xAxis = undefined
      let yAxis = undefined
      let series = undefined
      if (this.type == 'histogram' || this.type == 'line' || this.type == 'area') {
        xAxis = {
          ...this.settings.xAxis,
          show: this.settings.xAxis && this.settings.xAxis.axisLine.show
        }
        xAxis.nameLocation = 'end'
        xAxis.nameTextStyle = {
          color: textColor,
          fontSize: fontSize
        }
        xAxis.axisLabel = {
          color: textColor,
          fontSize: fontSize
        }

        yAxis = (axis) => {
          axis[0].name = this.settings.yAxis && this.settings.yAxis.name || ''
          axis[0].nameTextStyle = {
            color: textColor,
            fontSize: fontSize
          }
          axis[0].axisLabel = {
            color: textColor,
            fontSize: fontSize
          }
          if (this.settings.yAxis) {
            axis[0].show = this.settings.yAxis.axisLine.show
            axis[0].axisLine = this.settings.yAxis.axisLine
            axis[0].splitLine = this.settings.yAxis.splitLine
          }
          return axis
        }
      } else if (this.type == 'bar') {
        yAxis = {
          ...this.settings.yAxis,
          show: this.settings.yAxis && this.settings.yAxis.axisLine.show
        }
        yAxis.nameLocation = 'end'
        yAxis.nameTextStyle = {
          color: textColor,
          fontSize: fontSize
        }
        yAxis.axisLabel = {
          color: textColor,
          fontSize: fontSize
        }

        xAxis = (axis) => {
          axis[0].name = this.settings.xAxis && this.settings.xAxis.name || ''
          axis[0].nameTextStyle = {
            color: textColor,
            fontSize: fontSize
          }
          axis[0].axisLabel = {
            color: textColor,
            fontSize: fontSize
          }
          if (this.settings.xAxis) {
            axis[0].show = this.settings.xAxis.axisLine.show
            axis[0].axisLine = this.settings.xAxis.axisLine
            axis[0].splitLine = this.settings.xAxis.splitLine
          }
          return axis
        }
      }

      if (this.type == 'histogram' || this.type == 'bar') {
        series = {
          barWidth: this.settings.size && this.settings.size.width || ((this.settings.metrics && this.settings.metrics.length && ((this.settings.metrics.length == 1 ? 60 : 70) / this.settings.metrics.length) + '%') || '60%'),
          label: this.settings.series && this.settings.series.label || undefined,
        }
      }

      return {
        //textStyle: {color: '#f00'},
        grid: this.settings.grid,
        legend: legend,
        xAxis: xAxis,
        yAxis: yAxis,
        series: this.data && this.data.length > 0 ? series : undefined,
        color: this.settings && this.settings.theme && this.settings.theme.color || DEFAULT_COLORS
      }
    },
    vdata() {
      if (!this.data || this.data.length == 0 || !this.settings || !this.settings.dimension || this.settings.dimension.length == 0 || (this.type == 'map' && (!this.settings.metrics || this.settings.metrics.length == 0))) {
        if (this.type == 'map') {
          return {
            columns: ['', ''],
            rows: this.data || []
          }
        }
        return undefined
      }
      return {
        columns: this.settings ? ([].concat(this.settings.dimension).concat(this.settings.metrics)) : [],
        rows: this.data
      }
    },
    vsettings() {
      if (!this.data || this.data.length == 0 || !this.settings) {
        return undefined
      }
      if (this.type == 'area') {
        return {
          ...this.settings,
          area: true
        }
      }
      if (this.type == 'map') {
        if (!this.geojsons[this.settings.map.map]) {
          this.geojsons[this.settings.map.map] = require('@/assets/map/json/' + this.settings.map.map + '.json')
        }
        const settings = {
          position: this.settings.map.map,
          ...this.settings,
          mapOrigin: this.geojsons[this.settings.map.map] || {},
          label: {
            normal: {
              show: this.settings.map.normalLabelShow,
              textStyle: {
                color: this.settings.map.normalLabelColor,
                fontSize: this.settings.map.normalLabelFontSize
              }
            },
            emphasis: {
              show: this.settings.map.emphasisLabelShow,
              textStyle: {
                color: this.settings.map.emphasisLabelColor,
                fontSize: this.settings.map.emphasisLabelFontSize
              }
            }
          },
          itemStyle: {
            normal: {
              areaColor: this.settings.map.normalAreaColor,
              borderColor: this.settings.map.normalBorderColor,
              borderWidth: this.settings.map.normalBorderWidth,
            },
            emphasis: {
              areaColor: this.settings.map.emphasisAreaColor,
              borderColor: this.settings.map.emphasisBorderColor,
              borderWidth: this.settings.map.emphasisBorderWidth,
            }
          }
        }
        this.cleanProperties(settings.label)
        this.cleanProperties(settings.itemStyle)
        if (!this.settings.dimension || this.settings.dimension == 0 || !this.settings.metrics || this.settings.metrics.length == 0) {
          delete settings.dimension
          delete settings.metrics
        }
        return settings
      }
      return this.settings
    }
  }
}
</script>
