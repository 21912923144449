<template>
  <div id="widget-container" style="width: 100%; height: 100%; position: relative; overflow: hidden;">
    <!-- kpi & text & select -->
    <widget-text v-if="type=='kpi' || type=='text' || type=='select'" :type="type" :params="params" :data="data" :value="value" :settings="settings" :defaultSettings="defaultSettings" :readonly="readonly" @message="handleMessage" />

    <!-- image -->
    <widget-image v-else-if="type=='image'" :data="data" :value="value" :settings="settings" @message="handleMessage" />

    <!-- video -->
    <video v-else-if="type=='video'" :src="dataValue" autoplay loop height="100%" />

    <!-- rank -->
    <widget-rank v-else-if="type=='rank'" ref="widgets" :data="data" :value="value" :settings="settings" :readonly="readonly" @message="handleMessage" />

    <!-- web -->
    <iframe v-else-if="type=='web' && dataValue" :src="dataValue" :scrolling="!settings.scrollbar || !settings.scrollbar.hidden ? 'yes' : 'no'" frameborder="0"  style="width: 100%; height: 100%;" />

    <!-- datav -->
    <widget-datav v-else-if="type=='datav' && settings.datav" ref="widgets" :data="data" :settings="settings" :defaultSettings="defaultSettings" :readonly="readonly" @message="handleMessage" />

    <!-- echarts -->
    <widget-echarts v-else-if="type=='echarts'" ref="widgets" :params="params" :data="data" :settings="settings" :defaultSettings="defaultSettings" :readonly="readonly" @message="handleMessage" />

    <!-- v-charts -->
    <widget-vcharts v-else-if="data && type" ref="widgets" :type="type" :params="params" :data="data" :settings="settings" :defaultSettings="defaultSettings" :readonly="readonly" @message="handleMessage" />

    <!-- custom component -->
    <component v-else-if="!type && settings && settings.name" :is="settings.name" :params="params" :data="data" :settings="settings" :defaultSettings="defaultSettings" :readonly="readonly" @message="handleMessage"></component>

  </div>
</template>

<script>
import wecharts from './echarts.vue'
import wvcharts from './vcharts.vue'
import wdatav from './datav.vue'
import wimage from './image.vue'
import wtext from './text.vue'
import wrank from './rank.vue'
export default {
  name: "dashboard-widget",
  components: {
    'widget-echarts': wecharts,
    'widget-vcharts': wvcharts,
    'widget-datav': wdatav,
    'widget-image': wimage,
    'widget-text': wtext,
    'widget-rank': wrank
  },
  props: {
    title: {
      type: String,
      default: () => { return undefined }
    },
    type: {
      type: String,
      default: () => { return undefined }
    },
    params: {
      type: Object,
      default: () => { return undefined }
    },
    data: {
      type: Array,
      default: () => { return undefined }
    },
    value: {
      type: String,
      default: () => { return '' }
    },
    settings: {
      type: Object,
      default: () => { return undefined }
    },
    defaultSettings: {
      type: Object,
      default: () => { return undefined }
    },
    readonly: {
      type: Boolean,
      default: () => { return true }
    }
  },
  data() {
    return {
      dataValue: this.getDataValue()
    }
  },
  methods: {
    resize() {
      if (this.$refs.widgets) {
        this.$refs.widgets.resize()
      }
    },
    
    getDataValue() {
      if (!this.data || this.data.length == 0 || !this.settings || !this.settings.metrics || this.settings.metrics.length == 0) {
        return this.value
      }
      return this.data[0][this.settings.metrics[0]]
    },

    handleMessage(e1, e2, e3, e4, e5) {
      this.$emit('message', e1, e2, e3, e4, e5)
    }
  },
  watch: {
    value() {
      this.dataValue = this.getDataValue()
    },
    data() {
      this.dataValue = this.getDataValue()
    },
    settings() {
      this.dataValue = this.getDataValue()
    }
  }
}
</script>

<style scoped>
</style>