import request from '@/utils/request'

export function listBiDashboard(query) {
  return request({
    url: '/bi/bidashboard/list',
    method: 'get',
    params: query
  })
}

export function getBiDashboard(id) {
  return request({
    url: '/bi/bidashboard/get?id=' + id,
    method: 'get'
  })
}

export function addBiDashboard(data) {
  return request({
    url: '/bi/bidashboard/add',
    method: 'post',
    data: data
  })
}

export function editBiDashboard(data) {
  return request({
    url: '/bi/bidashboard/edit',
    method: 'post',
    data: data
  })
}

export function delBiDashboard(ids) {
  return request({
    url: '/bi/bidashboard/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function copyBiDashboard(id) {
  return request({
    url: '/bi/bidashboard/copy',
    method: 'post',
    data: {
      id: id
    }
  })
}
