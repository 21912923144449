<template>
  <!--<img :src="dataValue" style="width: 100%; height: 100%;" />-->
  <!-- 用img父容器必须设置高度，不然父容器有可能被撑开导致布局错乱 -->
  <div :style="imageStyle"></div>
</template>

<script>
export default {
  name: "widget-image",
  props: {
    data: {
      type: Array,
      default: () => { return undefined }
    },
    value: {
      type: String,
      default: () => { return '' }
    },
    settings: {
      type: Object,
      default: () => { return undefined }
    }
  },
  data() {
    return {}
  },
  methods: {
    getDataValue() {
      if (!this.data || this.data.length == 0 || !this.settings || !this.settings.metrics || this.settings.metrics.length == 0) {
        return this.value
      }
      return this.data[0][this.settings.metrics[0]]
    }
  },
  computed: {
    imageStyle() {
      const url = this.getDataValue()
      if (!url) {
        return ''
      }

      let style = 'width:100%;height:100%;background-image: url(' + url + ');background-repeat: no-repeat;'
      if (this.settings.image.scale == 'auto') {
        style += 'background-size: 100% 100%;'
      } else if (this.settings.image.scale == 'cover') {
        style += 'background-size: cover;'
      }

      return style
    }
  }
}
</script>
