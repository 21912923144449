<template>
  <div v-if="settings && settings.dimension" :style="style">
    <div v-if="settings.table && !settings.table.headerHidden" :style="headerStyle">
      <table class="item" style="width: 100%; border-collapse: collapse;" cellspacing="0" cellpadding="0">
        <tbody>
          <tr ref="th" :style="'height: ' + (settings.table && settings.table.headerHeight || 35) + 'px;'">
            <td v-if="!settings.table.indexHidden" :valign="settings.table.headerTextValign || 'middle'" :style="colStyle(0, 0)">#</td>
            <td v-for="(header, index) in settings.dimension" :key="index" :style="colStyle(index+(settings.table.indexHidden ? 0 : 1), 0)">{{header}}</td>
          </tr>
          <tr v-for="(item, index) in data" :key="index" style="visibility: hidden">
            <td v-if="!settings.table.indexHidden">{{index+1}}</td>
            <td v-for="(header, index) in settings.dimension" :key="index">{{item[header]}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <vue-seamless-scroll ref="seamlessScroll" :data="data" class="warp" :class-option="classOption">
      <table ref="table" class="item" style="width: 100%; border-collapse: collapse;" cellspacing="0" cellpadding="0">
        <tbody>
          <tr v-for="(item, rowIndex) in data" :key="rowIndex" :class="readonly && settings.event && settings.event.naviUrl ? 'over-item' : ''">
            <td v-if="!settings.table.indexHidden" :style="colStyle(0, rowIndex, true)">{{rowIndex+1}}</td>
            <td v-for="(header, colIndex) in settings.dimension" :key="colIndex" :style="colStyle(colIndex+(settings.table.indexHidden ? 0 : 1), rowIndex, true)">{{item[header]}}</td>
          </tr>
        </tbody>
      </table>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from '@/components/SeamlessScroll'
import { parseValue } from '../utils/common'
export default {
  name: "widget-rank",
  components: {
    vueSeamlessScroll
  },
  props: {
    data: {
      type: Array,
      default: () => { return undefined }
    },
    value: {
      type: String,
      default: () => { return '' }
    },
    settings: {
      type: Object,
      default: () => { return undefined }
    },
    readonly: {
      type: Boolean,
      default: () => { return true }
    }
  },
  data() {
    return {
      classOption: {
        limitMoveNum: 9,
        step: this.settings.table && (this.settings.table.step !== undefined ? this.settings.table.step : 1) || 1,
        singleHeight: this.settings.table && this.settings.table.singlePause ? (this.settings.table.rowHeight || 35) : 0,
        waitTime: (this.settings.table && this.settings.table.waitTime || 1) * 1000
      },
      colWidths: [],
      selectedRowIndex: -1,
      selectedRowId: undefined,
      selectedDataLength: 0
    }
  },
  mounted() {
    window.addEventListener("resize", this.onWindowResize);
    this.onWindowResize()
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      this.resize()
    },
    resize() {
      if (this.$refs.seamlessScroll) {
        const height = this.$refs.seamlessScroll.$el.offsetHeight
        if (height) {
          this.classOption = {
            ...this.classOption,
            limitMoveNum: Math.ceil(height / (this.settings.table && this.settings.table.rowHeight || 35))
          }
          this.$nextTick(() => {
            this.$refs.seamlessScroll.reset()
          })
        }
      }

      this.calcColWidths()
    },
    calcColWidths() {
      if (this.$refs.th) {
        const colWidths = []
        const children = this.$refs.th.children
        let total = 0
        for (let i = 0; i < children.length; i++) {
          const width = children[i].offsetWidth
          total += width
          colWidths.push(width)
        }
        if (total > 0) {
          for (let i = 0; i < colWidths.length; i++) {
            colWidths[i] = colWidths[i] / total * 100
          }
        }
        this.colWidths = colWidths
      }
    },
    colStyle(colIndex, rowIndex, isBody) {
      let style = ''
      if (!this.settings || !this.settings.table) {
        return style
      }
      if (!this.settings.table.indexHidden && colIndex == 0 && this.settings.table.indexColMinWidth) {
        style += 'min-width: ' + this.settings.table.indexColMinWidth + 'px;'
      } else if (this.settings.table.colMinWidth) {
        style += 'min-width: ' + this.settings.table.colMinWidth + 'px;'
      }
      if (isBody) {
        style += 'height: ' + (this.settings.table && this.settings.table.rowHeight || 35) + 'px;'
        let setColor = false
        let setBackgroundColor = false
        /*if (this.selectedRowIndex == rowIndex) {
          if (this.settings.table.rowTextColorSelect) {
            style += 'color: ' + this.settings.table.rowTextColorSelect + ';'
            setColor = true
          }
          if (!setBackgroundColor && this.settings.table.rowBackgroundColorSelect) {
            style += 'background-color: ' + this.settings.table.rowBackgroundColorSelect + ';'
            setBackgroundColor = true
          }
        }*/
        if (rowIndex % 2 == 0) {
          if (!setColor && this.settings.table.rowTextColor1) {
            style += 'color: ' + this.settings.table.rowTextColor1 + ';'
          }
          if (this.settings.table.rowBackgroundColor1) {
            style += 'background-color: ' + this.settings.table.rowBackgroundColor1 + ';'
          }
        }
        if (rowIndex % 2 == 1) {
          if (!setColor && this.settings.table.rowTextColor2) {
            style += 'color: ' + this.settings.table.rowTextColor2 + ';'
          }
          if (!setBackgroundColor && this.settings.table.rowBackgroundColor2) {
            style += 'background-color: ' + this.settings.table.rowBackgroundColor2 + ';'
          }
        }
      }
      if (this.settings.table.rowBorderColor) {
        if (this.settings.table.rowBorderLeft) {
          style += 'border-left: solid ' + this.settings.table.rowBorderLeft + 'px ' + this.settings.table.rowBorderColor + ';'
        }
        if (this.settings.table.rowBorderRight) {
          style += 'border-right: solid ' + this.settings.table.rowBorderRight + 'px ' + this.settings.table.rowBorderColor + ';'
        }
        if (this.settings.table.rowBorderTop) {
          style += 'border-top: solid ' + this.settings.table.rowBorderTop + 'px ' + this.settings.table.rowBorderColor + ';'
        }
        if (this.settings.table.rowBorderBottom) {
          style += 'border-bottom: solid ' + this.settings.table.rowBorderBottom + 'px ' + this.settings.table.rowBorderColor + ';'
        }
      }
      if (this.settings.table.padding) {
        style += 'padding-left: ' + this.settings.table.padding + 'px; padding-right: ' + this.settings.table.padding + 'px;'
      }
      if (!this.settings.table.textAlign) {
        if (colIndex == 0) {
          style += 'text-align: left;'
        } else if (colIndex == this.colWidths.length - 1) {
          style += 'text-align: right;'
        } else {
          style += 'text-align: center;'
        }
      } else {
        style += 'text-align: ' + this.settings.table.textAlign + ';'
      }
      if (colIndex >= this.colWidths.length) {
        return style
      }
      if (isBody) {
        style += 'width: ' + this.colWidths[colIndex] + '%;'
      }
      return style
    },
    setRowEventListener() {
      if (!this.readonly) {
        return
      }
      // 直接操作dom，因为无限滚动的原理是复制两份一样的内容
      if (this.$refs.table) {
        if (this.$refs.table.parentNode.parentNode.children.length > 0) {
          const realTable = this.$refs.table.parentNode.parentNode.children[0].children[0]
          if (realTable) {
            const tbody = realTable.children[0]
            for (let i = 0; i < tbody.children.length; i++) {
              tbody.children[i].onclick = () => {
                this.handleClick(this.data[i], i)
              }
            }
          }
        }
        if (this.$refs.table.parentNode.parentNode.children.length > 1) {
          const realTable = this.$refs.table.parentNode.parentNode.children[1].children[0]
          if (realTable) {
            const tbody = realTable.children[0]
            for (let i = 0; i < tbody.children.length; i++) {
              tbody.children[i].onclick = () => {
                this.handleClick(this.data[i], i)
              }
            }
          }
        }
      }
    },
    selectRow(rowIndex) {
      if (!this.readonly || !this.settings.table || (!this.settings.table.rowTextColorSelect && !this.settings.table.rowBackgroundColorSelect)) {
        return
      }
      // 直接操作dom，因为无限滚动的原理是复制两份一样的内容
      if (this.$refs.table) {
        if (this.$refs.table.parentNode.parentNode.children.length > 0) {
          const realTable = this.$refs.table.parentNode.parentNode.children[0].children[0]
          if (realTable && this.selectedRowIndex >= 0 && this.selectedRowIndex < realTable.children[0].children.length) {
            const realTableRow = realTable.children[0].children[this.selectedRowIndex]
            for (let i = 0; i < realTableRow.children.length; i++) {
              const realTableRowCol = realTableRow.children[i]
              if (this.selectedRowIndex % 2 == 0) {
                if (this.settings.table.rowTextColor1) {
                  realTableRowCol.style.color = this.settings.table.rowTextColor1
                } else {
                  realTableRowCol.style.color = ''
                }
                if (this.settings.table.rowBackgroundColor1) {
                  realTableRowCol.style.backgroundColor = this.settings.table.rowBackgroundColor1
                } else {
                  realTableRowCol.style.backgroundColor = ''
                }
              }
              if (this.selectedRowIndex % 2 == 1) {
                if (this.settings.table.rowTextColor2) {
                  realTableRowCol.style.color = this.settings.table.rowTextColor2
                } else {
                  realTableRowCol.style.color = ''
                }
                if (this.settings.table.rowBackgroundColor2) {
                  realTableRowCol.style.backgroundColor = this.settings.table.rowBackgroundColor2
                } else {
                  realTableRowCol.style.backgroundColor = ''
                }
              }
            }
          }
          if (realTable && rowIndex >= 0 && this.selectedRowIndex != rowIndex) {
            const realTableRow = realTable.children[0].children[rowIndex]
            for (let i = 0; i < realTableRow.children.length; i++) {
              const realTableRowCol = realTableRow.children[i]
              realTableRowCol.style.color = this.settings.table.rowTextColorSelect
              realTableRowCol.style.backgroundColor = this.settings.table.rowBackgroundColorSelect
            }
          }
        }
        if (this.$refs.table.parentNode.parentNode.children.length > 1) {
          const realTable = this.$refs.table.parentNode.parentNode.children[1].children[0]
          if (realTable && this.selectedRowIndex >= 0 && this.selectedRowIndex < realTable.children[0].children.length) {
            const realTableRow = realTable.children[0].children[this.selectedRowIndex]
            for (let i = 0; i < realTableRow.children.length; i++) {
              const realTableRowCol = realTableRow.children[i]
              if (this.selectedRowIndex % 2 == 0) {
                if (this.settings.table.rowTextColor1) {
                  realTableRowCol.style.color = this.settings.table.rowTextColor1
                } else {
                  realTableRowCol.style.color = ''
                }
                if (this.settings.table.rowBackgroundColor1) {
                  realTableRowCol.style.backgroundColor = this.settings.table.rowBackgroundColor1
                } else {
                  realTableRowCol.style.backgroundColor = ''
                }
              }
              if (this.selectedRowIndex % 2 == 1) {
                if (this.settings.table.rowTextColor2) {
                  realTableRowCol.style.color = this.settings.table.rowTextColor2
                } else {
                  realTableRowCol.style.color = ''
                }
                if (this.settings.table.rowBackgroundColor2) {
                  realTableRowCol.style.backgroundColor = this.settings.table.rowBackgroundColor2
                } else {
                  realTableRowCol.style.backgroundColor = ''
                }
              }
            }
          }
          if (realTable && rowIndex >= 0 && this.selectedRowIndex != rowIndex) {
            const realTableRow = realTable.children[0].children[rowIndex]
            for (let i = 0; i < realTableRow.children.length; i++) {
              const realTableRowCol = realTableRow.children[i]
              realTableRowCol.style.color = this.settings.table.rowTextColorSelect
              realTableRowCol.style.backgroundColor = this.settings.table.rowBackgroundColorSelect
            }
          }
        }
      }

      if (this.selectedRowIndex != rowIndex) {
        this.selectedRowIndex = rowIndex
        this.selectedDataLength = this.data.length
        this.$refs.seamlessScroll._setPause(true)
        return true
      } else {
        this.selectedRowIndex = -1
        this.selectedDataLength = 0
        this.$refs.seamlessScroll._setPause(false)
        return false
      }
    },
    handleClick(item, rowIndex) {
      if (!this.readonly) {
        return
      }

      if (!this.selectRow(rowIndex)) {
        return
      }

      let idField = this.settings.table && this.settings.table.idField
      if (!idField && this.settings.dimension && this.settings.dimension.length > 0) {
        idField = this.settings.dimension[0]
      }

      if (idField) {
        this.selectedRowId = item[idField]
        const params = this.params ? {...this.params} : {}
        if (this.settings && this.settings.event && this.settings.event.prefix) {
          params[this.settings.event.prefix + idField] = item[idField]
        }
        if (this.readonly && this.settings.event.naviUrl) {
          const naviUrl = parseValue(this.settings.event.naviUrl, params, this.$route && this.$route.query)
          this.$emit('message', 'open-url', this.settings.event.naviType, naviUrl, this)
        }
      }
    }
  },
  computed: {
    style() {
      let style = 'height: 100%; width: 100%; flex: 1; display: flex; flex-direction: column;'
      if (this.settings.text) {
        if (this.settings.text.color) {
          style += 'color: ' + this.settings.text.color + ';'
        } else if (this.defaultSettings && this.defaultSettings.textColor) {
          style += 'color: ' + this.defaultSettings.textColor + ';'
        }
        if (this.settings.text.fontSize) {
          style += 'font-size: ' + this.settings.text.fontSize + 'px;'
        } else if (this.defaultSettings && this.defaultSettings.textFontSize) {
          style += 'font-size: ' + this.defaultSettings.textFontSize + 'px;'
        }
        if (this.settings.text.bold) {
            style += 'font-weight: bold;'
        } else {
            style += 'font-weight: normal;'
        }
      } else {
        if (this.defaultSettings && this.defaultSettings.textColor) {
          style += 'color: ' + this.defaultSettings.textColor + ';'
        }
        if (this.defaultSettings && this.defaultSettings.textFontSize) {
          style += 'font-size: ' + this.defaultSettings.textFontSize + 'px;'
        }
      }
      return style
    },
    headerStyle() {
      let style = ''
      if (!this.settings || !this.settings.table) {
        return style
      }
      style += 'width: 100%; height: ' + (this.settings.table.headerHeight || 35) + 'px; overflow: hidden;'
      if (this.settings.table.headerBackgroundColor) {
        style += 'background-color: ' + this.settings.table.headerBackgroundColor + ';'
      }
      if (this.settings.table.headerBorderColor) {
        if (this.settings.table.headerBorderLeft) {
          style += 'border-left: solid ' + this.settings.table.headerBorderLeft + 'px ' + this.settings.table.headerBorderColor + ';'
        }
        if (this.settings.table.headerBorderRight) {
          style += 'border-right: solid ' + this.settings.table.headerBorderRight + 'px ' + this.settings.table.headerBorderColor + ';'
        }
        if (this.settings.table.headerBorderTop) {
          style += 'border-top: solid ' + this.settings.table.headerBorderTop + 'px ' + this.settings.table.headerBorderColor + ';'
        }
        if (this.settings.table.headerBorderBottom) {
          style += 'border-bottom: solid ' + this.settings.table.headerBorderBottom + 'px ' + this.settings.table.headerBorderColor + ';'
        }
      }
      if (this.settings.table.headerTextColor) {
        style += 'color: ' + this.settings.table.headerTextColor + ';'
      }
      if (this.settings.table.headerFontSize) {
        style += 'font-size: ' + this.settings.table.headerFontSize + 'px;'
      }
      if (this.settings.table.headerFontBold) {
        style += 'font-weight: bold;'
      }
      return style
    }
  },
  watch: {
    data(val) {
      this.$nextTick(() => {
        this.calcColWidths()
        this.$refs.seamlessScroll.reset()

        if (this.data) {
          if (this.selectedRowIndex >= 0) {
            if (this.selectedRowId !== undefined) {
              let idField = this.settings.table && this.settings.table.idField
              if (!idField && this.settings.dimension && this.settings.dimension.length > 0) {
                idField = this.settings.dimension[0]
              }

              if (idField) {
                let selectedRowId = undefined
                let selectedRowIndex = -1
                for (let i = 0; i < this.data.length; i++) {
                  const d = this.data[i]
                  if (d[idField] == this.selectedRowId) {
                    selectedRowId = this.selectedRowId
                    selectedRowIndex = i
                    break
                  }
                }
                setTimeout(() => {
                  this.selectRow(selectedRowIndex)
                }, 100)
                this.selectedRowId = selectedRowId
              }
            } else if (this.selectedDataLength != this.data.length) {
              setTimeout(() => {
                this.selectRow(-1)
              }, 100)
            }
          } else {
            if (this.$route && this.$route.query) {
              let idField = this.settings.table && this.settings.table.idField
              if (!idField && this.settings.dimension && this.settings.dimension.length > 0) {
                idField = this.settings.dimension[0]
              }

              let paramName = idField
              if (this.settings && this.settings.event && this.settings.event.prefix) {
                paramName = this.settings.event.prefix + idField
              }
              
              if (idField) {
                for (let key in this.$route.query) {
                  if (key == paramName) {
                    this.selectedRowId = this.$route.query[key]
                    break
                  }
                }

                if (this.selectedRowId) {
                  let selectedRowId = undefined
                  let selectedRowIndex = -1
                  for (let i = 0; i < this.data.length; i++) {
                    const d = this.data[i]
                    if (d[idField] == this.selectedRowId) {
                      selectedRowId = this.selectedRowId
                      selectedRowIndex = i
                      break
                    }
                  }
                  setTimeout(() => {
                    this.selectRow(selectedRowIndex)
                  }, 100)
                  this.selectedRowId = selectedRowId
                }
              }
            }
          }
        }

        setTimeout(() => {
          this.setRowEventListener()
        }, 100)
      })
    },
    settings(val) {
      this.classOption = {
        ...this.classOption,
        step: this.settings.table && (this.settings.table.step !== undefined ? this.settings.table.step : 1) || 1,
        singleHeight: this.settings.table && this.settings.table.singlePause ? (this.settings.table.rowHeight || 35) : 0,
        waitTime: (this.settings.table && this.settings.table.waitTime || 1) * 1000
      }
      this.$nextTick(() => {
        this.$refs.seamlessScroll.reset()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.warp {
  flex: 1;
  overflow: hidden;
  table {
    padding: 0;
    tr:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
}
.over-item:hover {
  cursor: pointer;
}
</style>