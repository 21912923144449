<template>
  <grid-layout ref="layout" style="width: 100%; min-height: 100%; flex: 1;"
    :layout.sync="layout"
    :col-num="settings.grid.cols"
    :row-height="rowHeight"
    :margin="[settings.grid.margin, settings.grid.margin]"
    :is-draggable="!readonly && !childrenDesign ? true : false"
    :is-resizable="!readonly && !childrenDesign ? true : false"
    :vertical-compact="settings.grid.vcompact"
    :prevent-collision="!settings.grid.vcompact"
    :prevent-overlap="!settings.grid.overlap"
    :use-css-transforms="true"
    @layout-updated="handleLayoutUpdated"
  >
    <grid-item
      v-for="item in layout"
      :key="item.i"
      :x="item.x"
      :y="item.y"
      :w="item.w"
      :h="item.h"
      :i="item.i"
    >
     <div ref="items" :class="'item-container ' + (!readonly && !childrenDesign ? (selectedItem && selectedItem.i == item.i ? 'item-selected' : 'item-normal') : '')" :style="widgetStyle(item)" @click.stop="handleItemClick(item)">
        <div :class="selectedItem && selectedItem.i == item.i ? 'item-drag' : 'item-no-drag'" :style="widgetInnerStyle(item)">
          <div :style="widgetInnerInnerStyle(item)">
            <div v-if="item.d.title" class="item-title" :style="widgetTitleStyle(item)">
              <div>{{item.d.title}}</div>
            </div>
            <div v-if="item.h > 1 || !item.d.title || (item.d.settings && item.d.settings.title && item.d.settings.title.hidden)" :data-key="item.i" ref="itemBody" class="item-body">
              <dashboard-widget v-if="layouted[item.i]" 
                ref="charts" 
                :title="item.d.title"
                :type="item.d.type" 
                :params="params"
                :data="modelDatas[item.d.model]" 
                :value="item.d.value"
                :settings="item.d && item.d.settings"
                :defaultSettings="settings.widget"
                :readonly="readonly"
                :data-key="item.i"
              />
              <div v-if="layouted[item.i] && item.children && item.settings && item.settings.grid.rowHeight && item.settings.grid.rowHeight > 0" 
                :style="'position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;' + (childrenDesign ? '' : 'pointer-events: none;')">
                <dashbord-widget-layout ref="childLayout"
                  :type="type"
                  :settings="item.settings"
                  :defaultChildrenSettings="defaultChildrenSettings"
                  :widgets="item.children"
                  :params="params"
                  :modelDatas="modelDatas"
                  :readonly="readonly"
                  :childrenDesign="childrenDesign"
                  :isRoot="false"
                >
                </dashbord-widget-layout>
              </div>
            </div>
          </div>
        </div>
      </div>
    </grid-item>
  </grid-layout>
</template>

<script>
import { GridLayout, GridItem } from "vue-grid-layout"
import Widget from './widgets/index.vue'
export default {
  name: 'dashbord-widget-layout',
  components: {
    GridLayout,
    GridItem,
    'dashboard-widget': Widget
  },
  props: {
    type: {
      type: String,
      default: () => { return 'pc' }
    },
    isRoot: {
      type: Boolean,
      default: () => { return true }
    },
    readonly: {
      type: Boolean,
      default: () => { return true }
    },
    widgets: {
      type: Array,
      default: () => { return undefined }
    },
    settings: {
      type: Object,
      default: () => { return {} }
    },
    defaultChildrenSettings: {
      type: Object,
      default: () => { return {} }
    },
    params: {
      type: Object,
      default: () => { return {} }
    },
    modelDatas: {
      type: Object,
      default: () => { return {} }
    },
    /*rowHeight: {
      type: Number,
      default: () => { return 0 }
    },*/
    childrenDesign: {
      type: Object,
      default: () => { return undefined }
    },
    selectedItem: {
      type: Object,
      default: () => { return undefined }
    },
    mouseOverItem: {
      type: Object,
      default: () => { return undefined }
    }
  },
  data() {
    return {
      layout: this.widgetsToLayout(),
      layouted: {},
      layoutHeight: 0,
      rowHeight: 0
    }
  },
  mounted() {
    window.addEventListener("resize", this.onWindowResize);
    this.$nextTick(() => {
      this.onWindowResize();
    })
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    widgetsToLayout() {
      const layout = []
      if (this.widgets) {
        for (let i = 0; i < this.widgets.length; i++) {
          const w = this.widgets[i]
          const l = w.layout[this.type]

          let childrenSettings = undefined
          if (w.settings) {
            childrenSettings = w.settings[this.type]
            if (!childrenSettings) {
              childrenSettings = JSON.parse(JSON.stringify(this.defaultChildrenSettings))
            }
          }

          layout.push({
            ...l,
            i: w.layout.i,
            d: w.data,
            settings: childrenSettings,
            children: w.widgets
          })
        }
      }
      return layout
    },

    onWindowResize() {
      if (this.isRoot) {
        if (this.readonly) {
          this.layoutHeight = document.documentElement.clientHeight
        } else {
          if (this.type == 'mobile') {
            this.layoutHeight = 720
          } else {
            this.layoutHeight = document.documentElement.clientHeight - 40
          }
        }
      } else {
        this.layoutHeight = this.$refs.layout.$el.parentNode.offsetHeight // parentNode高度才是自适应高度
      }

      //const colNum = this.settings.grid.cols
      const rowNum = this.settings.grid.rows
      const rowHeight = (this.layoutHeight - ((rowNum + 1) * this.settings.grid.margin)) / rowNum
      this.rowHeight = rowHeight

      this.$nextTick(() => {
        //setTimeout(() => {
          this.resize()
          this.updateChildrenLayoutRowHeight()
        //}, 100)
      })
    },

    resize() {
      this.chartsResize()
      this.updateChildrenLayoutRowHeight()
    },

    handleLayoutUpdated(layout) {
      this.$nextTick(() => {
        const layouted = {
          ...this.layouted
        }
        for (let i = 0; i < layout.length; i++) {
          const l = layout[i]
          layouted[l.i] = true
        }

        this.layouted = layouted

        this.$nextTick(() => {
          setTimeout(() => {
            this.chartsResize()
            this.updateChildrenLayoutRowHeight()
          }, 100)
        })
      })
    },

    updateChildrenLayoutRowHeight() {
      if (this.$refs.childLayout) {
        for (let i = 0; i < this.$refs.childLayout.length; i++) {
          this.$refs.childLayout[i].resize()
        }
      }
    },

    handleItemClick(item) {
      if (this.readonly || this.childrenDesign) {
        return
      }
      //this.setSelectedItem(item)
    },

    chartsResize() {
      const charts = this.$refs.charts
      if (charts) {
        for (let i = 0; i < charts.length; i++) {
          charts[i].resize()
        }
      }
    },

    widgetStyle(item) {
      let style = 'position: relative; display: flex; overflow: hidden; '

      if (!this.readonly && (!this.selectedItem || this.selectedItem.i != item.i) && this.mouseOverItem && this.mouseOverItem.i == item.i) {
        if (!this.childrenDesign || (item.pi && item.pi == this.childrenDesign.i)) {
          style += "border: dashed 1px #1890ff;"
        }
      }

      if (!this.readonly) {
        if (item.pi && (!this.childrenDesign || this.childrenDesign.i != item.pi)) {
          style += 'pointer-events: none;'
        } else {
          style += 'pointer-events: all;'
        }
      } else {
        if (item.d.settings.event && item.d.settings.event.pointerEvent) {
          style += 'pointer-events: all;'
        } else {
          style += 'pointer-events: none;'
        }
      }

      return style
    },

    widgetInnerStyle(item) {
      let style = 'position: relative; display: flex; flex: 1;'

      if (!item.pi) {
        style += 'padding-left: ' + (item.d.settings.padding.left !== undefined ? item.d.settings.padding.left : this.settings.grid.padding) + 'px;'
        style += 'padding-right: ' + (item.d.settings.padding.right !== undefined ? item.d.settings.padding.right : this.settings.grid.padding) + 'px;'
        style += 'padding-top: ' + (item.h > 1 ? (item.d.settings.padding.top !== undefined ? item.d.settings.padding.top : this.settings.grid.padding) : 0) + 'px;'
        style += 'padding-bottom: ' + (item.h > 1 ? (item.d.settings.padding.bottom !== undefined ? item.d.settings.padding.bottom : this.settings.grid.padding) : 0) + 'px;'
      } else {
        for (let i = 0; i < this.layout.length; i++) {
          const l = this.layout[i]
          if (l.i == item.pi && l.settings) {
            style += 'padding-left: ' + (item.d.settings.padding.left !== undefined ? item.d.settings.padding.left : l.settings.grid.padding) + 'px;'
            style += 'padding-right: ' + (item.d.settings.padding.right !== undefined ? item.d.settings.padding.right : l.settings.grid.padding) + 'px;'
            style += 'padding-top: ' + (item.h > 1 ? (item.d.settings.padding.top !== undefined ? item.d.settings.padding.top : l.settings.grid.padding) : 0) + 'px;'
            style += 'padding-bottom: ' + (item.h > 1 ? (item.d.settings.padding.bottom !== undefined ? item.d.settings.padding.bottom : l.settings.grid.padding) : 0) + 'px;'
            break
          }
        }
      }

      const background = item.d.settings && item.d.settings.background
       
      if (background && background.color) {
        style += 'background-color: ' + background.color + ';'
      } else {
        if (!item.pi && this.settings.widget && this.settings.widget.backgroundColor) {
          style += 'background-color: ' + this.settings.widget.backgroundColor + ';'
        }
      }

      if (background && background.image) {
        style += 'background-image: url(' + background.image + ');background-repeat: no-repeat;'
        if (background.imageScale == 'auto') {
          style += 'background-size: 100% 100%;'
        } else if (background.imageScale == 'cover') {
          style += 'background-size: cover;'
        }
      }

      const border = item.d.settings && item.d.settings.border
      if (border) {
        const numReg = /^[0-9]+$/
        style += 'border-style: solid;'
        if (border.color) {
          style += 'border-color: ' + border.color + ';'
        }
        if (border.left) {
          if (numReg.test(border.left)) {
            style += 'border-left-width: ' + border.left + 'px;'
          } else {
            style += 'border-left-width: ' + border.left + ';'
          }
        } else {
          style += 'border-left-width: 0px;'
        }
        if (border.right) {
          if (numReg.test(border.right)) {
            style += 'border-right-width: ' + border.right + 'px;'
          } else {
            style += 'border-right-width: ' + border.right + ';'
          }
        } else {
          style += 'border-right-width: 0px;'
        }
        if (border.top) {
          if (numReg.test(border.top)) {
            style += 'border-top-width: ' + border.top + 'px;'
          } else {
            style += 'border-top-width: ' + border.top + ';'
          }
        } else {
          style += 'border-top-width: 0px;'
        }
        if (border.bottom) {
          if (numReg.test(border.bottom)) {
            style += 'border-bottom-width: ' + border.bottom + 'px;'
          } else {
            style += 'border-bottom-width: ' + border.bottom + ';'
          }
        } else {
          style += 'border-bottom-width: 0px;'
        }
        if (border.image) {
          style += 'border-image-source: url(' + border.image + ');'
        }
        if (border.imageSliceLeft || border.imageSliceRight || border.imageSliceTop || border.imageSliceBottom) {
          style += 'border-image-slice: ' + (border.imageSliceTop || border.imageSliceBottom || 0) + ' ' + (border.imageSliceRight || border.imageSliceLeft || 0) + ' ' + (border.imageSliceBottom || border.imageSliceTop || 0) + ' ' + (border.imageSliceLeft || border.imageSliceRight || 0) + ';'
        }
        if (border.imageRepeat) {
          style += 'border-image-repeat: ' + border.imageRepeat + ';'
        }
        if (border.radius) {
          if (numReg.test(border.radius)) {
            style += 'border-radius: ' + border.radius + 'px;'
          } else {
            style += 'border-radius: ' + border.radius + ';'
          }
        }
      }
      return style
    },

    widgetInnerInnerStyle(item) {
      let style = 'position: relative; overflow: hidden; display: flex; flex-direction: column; flex: 1;'
      const border = item.d.settings && item.d.settings.border
      if (border && (border.color || border.image)) {
        const numReg = /^[0-9]+$/
        if (border.left) {
          if (numReg.test(border.left)) {
            style += 'margin-left: -' + border.left + 'px;'
          } else {
            style += 'margin-left: -' + border.left + ';'
          }
        }
        if (border.right) {
          if (numReg.test(border.right)) {
            style += 'margin-right: -' + border.right + 'px;'
          } else {
            style += 'margin-right: -' + border.right + ';'
          }
        }
        if (border.top) {
          if (numReg.test(border.top)) {
            style += 'margin-top: -' + border.top + 'px;'
          } else {
            style += 'margin-top: -' + border.top + ';'
          }
        }
        if (border.bottom) {
          if (numReg.test(border.bottom)) {
            style += 'margin-bottom: -' + border.bottom + 'px;'
          } else {
            style += 'margin-bottom: -' + border.bottom + ';'
          }
        }
      }
      return style
    },

    titleAlign(align) {
      if (align == 'center') {
        return 'center'
      } else if (align == 'right') {
        return 'flex-end'
      } else {
        return 'flex-start'
      }
    },

    widgetTitleStyle(item) {
      if (!item.d.settings || !item.d.settings.title) {
        return ''
      }

      const title = item.d.settings.title
      let style = 'justify-content: ' + this.titleAlign(title.align) + '; font-size: ' + (title.fontSize || (this.settings.widget && this.settings.widget.titleFontSize) || 18) + 'px; color: ' + (title.color || (this.settings.widget && this.settings.widget.titleColor) || '#000') + '; ' + (title.hidden ? 'display: none;' : '')
      if (title.bold) {
          style += 'font-weight: bold;';
      } else {
          style += 'font-weight: normal;';
      }
      if (item.h == 1) {
        style += "margin-bottom: 0px; align-items: center; flex: 1;"
      }

      return style
    },
  },
  watch: {
    type() {
      this.layout = this.widgetsToLayout()
      this.onWindowResize()
    },
    widgets() {
      this.layout = this.widgetsToLayout()
    },
    settings() {
      this.onWindowResize()
    }
  }
}
</script>

<style scoped>
.vue-grid-layout {
  /*background: #f5f5f5;*/
}
.vue-grid-item:not(.vue-grid-placeholder) {
  /*background: #fff;*/
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .item-container {
  border: 1px solid rgba(0,0,0,0);
  font-size: 18px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
}
.vue-grid-item .sitem-container {
  border: 1px solid rgba(0,0,0,0);
  font-size: 18px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
}
.item-title {
  display: flex;
  margin-bottom: 8px;
}
.item-title div {
  cursor: default;
}
.item-body {
  flex: 1;
  position: relative;
  overflow: hidden;
}
.vue-grid-item .item-normal:hover {
  border: 1px dashed #1890ff;
}
.vue-grid-item .item-selected {
  border: 1px solid #1890ff;
  z-index: 100;
}
.vue-grid-item .sitem-normal {
  border: 1px dashed #ccc;
}
.vue-grid-item .sitem-normal:hover {
  border: 1px dashed #1890ff;
}
.vue-grid-item .sitem-selected {
  border: 1px solid #1890ff;
  z-index: 100;
}
.vue-grid-item .item-drag {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  /*overflow: hidden;*/
}
.vue-grid-item .item-no-drag {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  /*overflow: hidden;*/
}
.vue-grid-item .sitem-drag {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  /*overflow: hidden;*/
}
.vue-grid-item .sitem-no-drag {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  /*overflow: hidden;*/
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-grid-item-selected {
  z-index: 1;
}
</style>