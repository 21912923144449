import moment from 'moment'
import Qs from 'qs'

export function parseValue(value, params, query) {
  // {{param:defaultvalue:paramvalue1=v1&paramvalue2=v2}}
  let li = 0
  let si = -1
  let sn = 0
  let ei = -1
  let en = 0
  let s = ''
  for (let i = 0; i < value.length; i++) {
    const c = value[i]
    if (c == '{') {
      si = i
      sn++
    } else if (c == '}' && sn >= 2) {
      if (ei == -1) {
        ei = i
      }
      en++
      if (en >= 2) {
        s += value.substr(li, si - li - 1)
        const v = value.substr(si + 1, ei - si - 1)
        const sps = v.split(':')
        const sps0 = sps[0]
        let pv = undefined
        if (sps0 == 'year()') {
          pv = moment().format('yyyy')
        } else if (sps0 == 'month()') {
          pv = moment().format('M')
        } else if (sps0 == 'day()') {
          pv = moment().format('D')
        } else if (sps0 == 'hour()') {
          pv = moment().format('HH')
        } else if (sps0 == 'minute()') {
          pv = moment().format('mm')
        } else if (sps0 == 'second()') {
          pv = moment().format('ss')
        } else {
          pv = params && params[sps0]
          if (!pv && query) {
            pv = query[sps0]
          }
        }
        if (pv !== undefined) {
          if (sps.length > 2) {
            const sps2 = sps[2]
            if (sps2) {
              const qs = Qs.parse(sps2)
              if (qs) {
                const pv1 = qs[pv]
                if (pv1 !== undefined) {
                  pv = pv1
                }
              }
            }
          }
          s += pv
        } else {
          s += sps.length > 1 ? sps[1] : sps0
        }
        li = ei + 2
        si = -1
        sn = 0
        ei = -1
        en = 0
      }
    } else {
      if (sn == 1) {
        si = -1
        sn = 0
      }
      if (en == 1) {
        si = -1
        sn = 0
        ei = -1
        en = 0
      }
    }
  }
  if (value.length - li > 0) {
    s += value.substr(li, value.length - li)
  }
  return s
}