<template>
  <div ref="echarts" style="width: 100%; height: 100%;"></div>
</template>

<script>
import $echarts from 'echarts'
import { parseValue } from '../utils/common'

const DEFAULT_COLORS = ['#19d4ae', '#5ab1ef', '#fa6e86', '#ffb980', '#0067a6', '#c4b4e4', '#d87a80', '#9cbbff', '#d9d0c7', '#87a997', '#d49ea2', '#5b4947', '#7ba3a8']

export default {
  name: "widget-pie",
  props: {
    type: {
      type: String,
      default: () => { return undefined }
    },
    data: {
      type: Array,
      default: () => { return undefined }
    },
    settings: {
      type: Object,
      default: () => { return undefined }
    },
    defaultSettings: {
      type: Object,
      default: () => { return undefined }
    },
    readonly: {
      type: Boolean,
      default: () => { return true }
    }
  },
  data() {
    return {
      echarts: undefined
    }
  },
  mounted() {
    this.echarts = $echarts.init(this.$refs.echarts);
    this.echarts.on('click', (e) => {
      console.log(e)
      const params = this.params ? {...this.params} : {}
      if (this.settings && this.settings.event && this.settings.event.prefix) {
        params[this.settings.event.prefix + 'name'] = e.name
        params[this.settings.event.prefix + 'seriesName'] = e.seriesName
      }
      if (this.readonly && this.settings.event.naviUrl) {
        const naviUrl = parseValue(this.settings.event.naviUrl, params, this.$route && this.$route.query)
        this.$emit('message', 'open-url', this.settings.event.naviType, naviUrl, this)
      }
    })
    this.setOption()
    window.addEventListener("resize", this.onWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      this.resize()
    },
    resize() {
      this.echarts.resize()
    },
    setOption() {
      if (!this.settings || !this.echarts) {
        return
      }

      const textColor = (this.settings && this.settings.text.color) || (this.defaultSettings && this.defaultSettings.textColor) || '#000'
      const fontSize = (this.settings && this.settings.text.fontSize) || (this.defaultSettings && this.defaultSettings.textFontSize) || 12

      const legend = {
        textStyle: {
          color: textColor,
          fontSize: fontSize
        }
      }
      if (this.settings.legend) {
        legend.show = this.settings.legend.show
        legend.orient = this.settings.legend.orient

        if (this.settings.legend.align == 'left') {
          if (this.settings.legend.alignOffset) {
            legend.left = this.settings.legend.alignOffset
          } else {
            legend.left = 'left'
          }
        } else if (this.settings.legend.align == 'right') {
          if (this.settings.legend.alignOffset) {
            legend.right = this.settings.legend.alignOffset
          } else {
            legend.left = 'right'
          }
        } else {
          legend.left = this.settings.legend.align
        }

        if (this.settings.legend.valign == 'top') {
          if (this.settings.legend.valignOffset) {
            legend.top = this.settings.legend.valignOffset
          } else {
            legend.top = 'top'
          }
        } else if (this.settings.legend.valign == 'bottom') {
          if (this.settings.legend.valignOffset) {
            legend.bottom = this.settings.legend.valignOffset
          } else {
            legend.top = 'bottom'
          }
        } else {
          legend.top = this.settings.legend.valign
        }
      }

      const echarts = $echarts
      const data = this.data
      const settings = {
        dimension: this.settings.dimension,
        metrics: this.settings.metrics,
        title: {
          title: this.settings && this.settings.title.title || '',
          color: (this.settings && this.settings.title.color) || (this.defaultSettings && this.defaultSettings.titleColor) || '#000',
          fontSize: (this.settings && this.settings.title.fontSize) || (this.defaultSettings && this.defaultSettings.titleFontSize) || 18
        },
        text: {
          color: textColor,
          fontSize: fontSize
        },
        legend: legend,
        grid: this.settings.grid,
        size: this.settings.size,
        theme: this.settings.theme
      }
      const option = this.getOption(data, settings)
      //console.log(option)
      if (option) {
        this.echarts.setOption(option, true);
      }
    },
    getOption (data, settings) {
      var dimensions = settings && settings.dimension  && settings.dimension.length > 0 ? [settings.dimension[0]] : []
      var series = []
      if (settings && settings.metrics) {
        for (var i = 0; i < settings.metrics.length; i++) {
            series.push({
                type: 'pie',
                radius: this.type == 'pie' ? (settings.size && settings.size.outsideRadius || '60%') : [settings.size && settings.size.insideRadius || '45%', settings.size && settings.size.outsideRadius || '60%'],
                selectedMode: 'single'
            })
            dimensions.push(settings.metrics[i])
        }
      }
      var option = {
          legend: settings && settings.legend,
          tooltip: {},
          dataset: {
              dimensions: dimensions,
              source: data || []
          },
          series: series,
          color: settings && settings.theme && settings.theme.color || DEFAULT_COLORS
      };
      return option
    }
  },
  watch: {
    type() {
      this.setOption()
    },
    data() {
      this.setOption()
    },
    settings() {
      this.setOption()
    },
    defaultSettings() {
      this.setOption()
    }
  }
}
</script>
