<template>
  <component v-if="settings.datav.type=='dv-loading'" :is="settings.datav.type" ref="datav" />
  <component v-else-if="settings.datav.type=='dv-border-box' || settings.datav.type=='dv-decoration'" :is="settings.datav.subType" v-bind="option" ref="datav" :dur="settings.datav.dur" />
  <component v-else-if="settings.datav.type=='dv-charts'" :is="settings.datav.type" :option="option" ref="datav" />
  <component v-else :is="settings.datav.type" :config="option" style="width:100%; height:100%;" ref="datav" />
</template>

<script>
export default {
  name: "widget-datav",
  props: {
    data: {
      type: Array,
      default: () => { return undefined }
    },
    settings: {
      type: Object,
      default: () => { return undefined }
    },
    defaultSettings: {
      type: Object,
      default: () => { return undefined }
    },
    readonly: {
      type: Boolean,
      default: () => { return true }
    }
  },
  data() {
    return {
      option: this.getOption()
    }
  },
  methods: {
    resize() {
      if (this.$refs.datav && this.$refs.datav.debounceInitWHFun) {
        this.$refs.datav.debounceInitWHFun()
      }
    },
    getOption() {
      if (!this.settings || !this.settings.dataFormatter) {
        if (this.settings && (this.settings.datav.type=='dv-border-box' || this.settings.datav.type=='dv-decoration')) {
          return {
            reverse: this.settings.datav.reverse
          }
        }
        return undefined
      }

      const data = this.data
      const settings = {
        dimension: this.settings.dimension,
        metrics: this.settings.metrics,
        title: {
          title: this.settings && this.settings.title.title || '',
          color: (this.settings && this.settings.title.color) || (this.defaultSettings && this.defaultSettings.titleColor) || '#000',
          fontSize: (this.settings && this.settings.title.fontSize) || (this.defaultSettings && this.defaultSettings.titleFontSize) || 18
        },
        text: {
          color: (this.settings && this.settings.text.color) || (this.defaultSettings && this.defaultSettings.textColor) || '#000',
          fontSize: (this.settings && this.settings.text.fontSize) || (this.defaultSettings && this.defaultSettings.textFontSize) || 12
        }
      }
      const option = eval('(' + this.settings.dataFormatter + ')(data, settings)')

      if (this.settings && (this.settings.datav.type=='dv-border-box' || this.settings.datav.type=='dv-decoration') && (!option || option.reverse === undefined)) {
        if (!option) {
          return {
            reverse: this.settings.datav.reverse
          }
        } else {
          option.reverse = this.settings.datav.reverse
        }
      }

      return option
    }
  },
  watch: {
    data() {
      this.option = this.getOption()
    },
    settings() {
      this.option = this.getOption()
    },
    defaultSettings() {
      this.option = this.getOption()
    }
  }
}
</script>
