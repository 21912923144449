<!-- @author zhengjie -->
<template>
  <div class="icon-body">
    <el-input v-model="name" style="position: relative;" clearable placeholder="请输入图标名称" @clear="filterIcons" @input.native="filterIcons">
      <i slot="suffix" class="el-icon-search el-input__icon" />
    </el-input>
    <el-tabs v-model="activeName" @tab-click="filterIcons">
      <el-tab-pane label="SvgIcon图标" name="svg">
        <div class="icon-list">
          <div v-for="(item, index) in svgIconList" :key="index" @click="selectedIcon(item)">
            <svg-icon :icon-class="item" style="height: 30px;width: 16px;" />
            <span>{{ item }}</span>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Element图标" name="element">
        <div class="icon-list">
          <div v-for="(item, index) in elementIconList" :key="index" @click="selectedIcon(item)">
            <span style="font-size: 14px"><i :class="item" /> {{ item }}</span>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Font Awesome图标" name="awesome">
        <div class="icon-list">
          <div v-for="(item, index) in awesomeIconList" :key="index" @click="selectedIcon(item)" style="width: 50%;">
            <span style="font-size: 1em"><i :class="item" /> {{ item.replace('fa fa-','') }}</span>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
  import svgIcons from './requireIcons'
  import elementIcons from './elementIcons'
  import awesomeIcons from './awesomeIcons'
  export default {
    name: 'IconSelect',
    data() {
      return {
        activeName:'svg',
        name: '',
        svgIconList: svgIcons,
        elementIconList: elementIcons,
        awesomeIconList: awesomeIcons,
      }
    },
    methods: {
      filterIcons() {
        if(this.activeName === 'svg') {
          if (this.name) {
            this.svgIconList = this.svgIconList.filter(item => item.includes(this.name))
          } else {
            this.svgIconList = svgIcons
          }
        }else if(this.activeName === 'element') {
          if (this.name) {
            this.elementIconList = this.elementIconList.filter(item => item.includes(this.name))
          } else {
            this.elementIconList = elementIcons
          }
        }else if(this.activeName === 'awesome') {
          if (this.name) {
            this.awesomeIconList = this.awesomeIconList.filter(item => item.includes(this.name))
          } else {
            this.awesomeIconList = awesomeIcons
          }
        }

      },
      selectedIcon(name) {
        this.$emit('selected', name)
        document.body.click()
      },
      reset() {
        this.name = ''
        this.svgIconList = svgIcons
        this.elementIconList = elementIcons
        this.awesomeIconList = awesomeIcons
      }
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .icon-body {
    width: 100%;
    padding: 10px;
    .icon-list {
      height: 200px;
      overflow-y: scroll;
      div {
        height: 30px;
        line-height: 30px;
        margin-bottom: -5px;
        cursor: pointer;
        width: 33%;
        float: left;
        overflow: hidden;
      }
      span {
        display: inline-block;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
  }
</style>
