<template>
  <div style="width: 100%; height: 100%; position: relative;">
    <div ref="echarts" style="width: 100%; height: 100%;"></div>
    <div v-if="mapStack && mapStack.length > 1" class="back-button" :style="backButtonStyle" @click="handleBack">返回上一级</div>
  </div>
</template>

<script>
import $echarts from 'echarts'
import {getBiDatascreenMap} from '@/api/bi/bi_datascreen_map'
import { parseValue } from '../utils/common'
// vue-echarts
export default {
  name: "widget-echarts",
  props: {
    params: {
      type: Object,
      default: () => { return undefined }
    },
    data: {
      type: Array,
      default: () => { return undefined }
    },
    settings: {
      type: Object,
      default: () => { return undefined }
    },
    defaultSettings: {
      type: Object,
      default: () => { return undefined }
    },
    readonly: {
      type: Boolean,
      default: () => { return true }
    }
  },
  data() {
    return {
      echarts: undefined,
      geoJson: undefined,
      mapStack: []
    }
  },
  mounted() {
    this.echarts = $echarts.init(this.$refs.echarts);
    this.initEvents()
    this.setOption()
    window.addEventListener("resize", this.onWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      setTimeout(() => {
        this.resize()
      }, 100)
    },
    resize() {
      this.echarts.resize()
    },
    loadMapIfNeeded(option, callback) {
      const maps = {}
      if (option.geo && option.geo.map) {
        maps[option.geo.map] = option.geo.map
      }
      if (option.series) {
        for (let i = 0; i < option.series.length; i++) {
          const s = option.series[i]
          if (s.type == 'map' && s.map) {
            maps[s.map] = s.map
          }
        }
      }
      let async = false
      for (let map in maps) {
        const mdata = $echarts.getMap(map)
        if (!mdata) {
          if (/[0-9]/.test(map)) {
            async = true
            getBiDatascreenMap(map).then(responce => {
              this.geoJson = JSON.parse(responce.data.data)
              $echarts.registerMap(map, this.geoJson)
              callback && callback()
            })
          } else {
            this.geoJson = require('@/assets/map/json/' + map + '.json')
            $echarts.registerMap(map, this.geoJson)
          }
        } else {
          this.geoJson = mdata.geoJson
        }
      }
      if (!async) {
        callback && callback()
      }
    },
    loadProvince(name) {
      const geoJson = require('@/assets/map/json/province/' + name + '.json')
      return geoJson
    },
    initEvents() {
      this.echarts.getZr().on('click', (e) => {
      })
      this.echarts.on('click', (e) => {
        console.log(e)
        const params = this.params ? {...this.params} : {}
        if (this.settings && this.settings.event && this.settings.event.prefix) {
          params[this.settings.event.prefix + 'name'] = e.name
          for (let i = 0; i < this.mapStack.length; i++) {
            const ms = this.mapStack[i]
            params[this.settings.event.prefix + 'name' + i] = ms.name
          }
          params[this.settings.event.prefix + 'seriesName'] = e.seriesName
        }
        if (this.readonly && this.settings.event.naviUrl) {
          const naviUrl = parseValue(this.settings.event.naviUrl, params, this.$route && this.$route.query)
          this.$emit('message', 'open-url', this.settings.event.naviType, naviUrl, this)
        }
        if (e.componentType == "geo" || e.seriesType == 'map') {
          if (this.mapStack.length > 0) {
            const ms = this.mapStack[this.mapStack.length - 1]
            if (e.name != ms.name) {
              let nms = undefined
              for (let i = 0; i < ms.geoJson.features.length; i++) {
                const feature = ms.geoJson.features[i]
                if (feature.properties.name == e.name) {
                  let geoJson = undefined
                  let option = undefined
                  if (ms.option.selectMode == 'detail') {
                    geoJson = JSON.parse(JSON.stringify(ms.geoJson))
                    geoJson.features = [feature]
                    option = JSON.parse(JSON.stringify(ms.option))
                    if (option.geo && option.geo.map) {
                      option.geo.map = e.name
                      if (option.geo.regions) {
                        for (let j = 0; j < option.geo.regions.length; j++) {
                          const r = option.geo.regions[j]
                          if (r.name == e.name && r.regions) {
                            option.geo.regions = r.regions
                            break
                          }
                        }
                      }
                    }
                    const mdata = $echarts.getMap(e.name)
                    if (!mdata) {
                      if (e.name == '安徽') {
                        geoJson = this.loadProvince('anhui')
                      } else if (e.name == '澳门') {
                        //geoJson = this.loadProvince('aomen')
                      } else if (e.name == '北京') {
                        //geoJson = this.loadProvince('beijing')
                      } else if (e.name == '重庆') {
                        //geoJson = this.loadProvince('chongqing')
                      } else if (e.name == '福建') {
                        geoJson = this.loadProvince('fujian')
                      } else if (e.name == '甘肃') {
                        geoJson = this.loadProvince('gansu')
                      } else if (e.name == '甘肃') {
                        geoJson = this.loadProvince('gansu')
                      } else if (e.name == '广东') {
                        geoJson = this.loadProvince('guangdong')
                      } else if (e.name == '广西') {
                        geoJson = this.loadProvince('guangxi')
                      } else if (e.name == '贵州') {
                        geoJson = this.loadProvince('guizhou')
                      } else if (e.name == '海南') {
                        geoJson = this.loadProvince('hainan')
                      } else if (e.name == '河北') {
                        geoJson = this.loadProvince('hebei')
                      } else if (e.name == '黑龙江') {
                        geoJson = this.loadProvince('heilongjiang')
                      } else if (e.name == '河南') {
                        geoJson = this.loadProvince('henan')
                      } else if (e.name == '湖北') {
                        geoJson = this.loadProvince('hubei')
                      } else if (e.name == '湖南') {
                        geoJson = this.loadProvince('hunan')
                      } else if (e.name == '江苏') {
                        geoJson = this.loadProvince('jiangsu')
                      } else if (e.name == '江西') {
                        geoJson = this.loadProvince('jiangxi')
                      } else if (e.name == '吉林') {
                        geoJson = this.loadProvince('jilin')
                      } else if (e.name == '辽宁') {
                        geoJson = this.loadProvince('liaoning')
                      } else if (e.name == '内蒙古') {
                        geoJson = this.loadProvince('neimenggu')
                      } else if (e.name == '宁夏') {
                        geoJson = this.loadProvince('ningxia')
                      } else if (e.name == '青海') {
                        geoJson = this.loadProvince('qinghai')
                      } else if (e.name == '山东') {
                        geoJson = this.loadProvince('shandong')
                      } else if (e.name == '上海') {
                        //geoJson = this.loadProvince('shanghai')
                      } else if (e.name == '山西') {
                        geoJson = this.loadProvince('shanxi')
                      } else if (e.name == '陕西') {
                        geoJson = this.loadProvince('shanxi1')
                      } else if (e.name == '四川') {
                        geoJson = this.loadProvince('sichuan')
                      } else if (e.name == '台湾') {
                        //geoJson = this.loadProvince('taiwan')
                      } else if (e.name == '天津') {
                        //geoJson = this.loadProvince('tianjin')
                      } else if (e.name == '香港') {
                        //geoJson = this.loadProvince('xianggang')
                      } else if (e.name == '新疆') {
                        geoJson = this.loadProvince('xinjiang')
                      } else if (e.name == '西藏') {
                        geoJson = this.loadProvince('xizang')
                      } else if (e.name == '云南') {
                        geoJson = this.loadProvince('yunnan')
                      } else if (e.name == '浙江') {
                        geoJson = this.loadProvince('zhejiang')
                      }
                      if (geoJson) {
                        $echarts.registerMap(e.name, geoJson)
                      }
                    } else {
                      geoJson = mdata.geoJson
                    }
                    
                    if (option.series) {
                      for (let i = 0; i < option.series.length; i++) {
                        const s = option.series[i]
                        if (s.type == 'map' && s.map) {
                          s.map = e.name
                        }
                      }
                    }
                  }
                  nms = {
                    name: e.name,
                    geoJson: geoJson,
                    option: option
                  }
                  break
                }
              }

              if (nms) {
                if (ms.option.selectMode == 'detail') {
                  if (!$echarts.getMap(e.name)) {
                    $echarts.registerMap(e.name, nms.geoJson)
                  }
                  this.mapStack.push(nms)
                  this.echarts.clear()
                  this.echarts.setOption(nms.option, true)
                }
                window.postMessage({
                  type: 'bi_param_set',
                  key: (this.settings && this.settings.event && this.settings.event.prefix || '') + 'name',
                  value: nms.name
                })
                for (let i = 0; i < this.mapStack.length; i++) {
                  const ms = this.mapStack[i]
                  window.postMessage({
                    type: 'bi_param_set',
                    key: (this.settings && this.settings.event && this.settings.event.prefix || '') + 'name' + i,
                    value: ms.name
                  })
                }
              }
            } else {
              if (ms.option.selectMode == 'detail') {
                this.echarts.clear()
                this.echarts.setOption(ms.option, true)
              }
            }
          }
        }
      })

      this.echarts.on('mouseover', (e) => {
        //console.log('mouseover', e)
        const params = this.params ? {...this.params} : {}
        if (this.settings && this.settings.event && this.settings.event.prefix) {
          params[this.settings.event.prefix + 'name'] = e.name
          params[this.settings.event.prefix + 'seriesName'] = e.seriesName
          if (e.componentType == "geo") {
            let mapStack = '/'
            for (let i = 0; i < this.mapStack.length; i++) {
              mapStack += this.mapStack[i].name + '/'
            }
            params['_map_stack_'] = mapStack
          }
        }
        if (this.readonly && this.settings.event.popoverUrl) {
          const popoverUrl = parseValue(this.settings.event.popoverUrl, params, this.$route && this.$route.query)
          this.$emit('message', 'show-popover-url', e, popoverUrl, this)
        }
      })

      this.echarts.on('mouseout', (e) => {
        //console.log('mouseout', e.name)
        const params = this.params ? {...this.params} : {}
        if (this.settings && this.settings.event && this.settings.event.prefix) {
          params[this.settings.event.prefix + 'name'] = e.name
          params[this.settings.event.prefix + 'seriesName'] = e.seriesName
        }
        if (this.readonly && this.settings.event.popoverUrl) {
          const popoverUrl = parseValue(this.settings.event.popoverUrl, params, this.$route && this.$route.query)
          this.$emit('message', 'hide-popover-url', e, popoverUrl, this)
        }
      })
    },
    setOption() {
      if (!this.settings || !this.settings.dataFormatter || !this.echarts) {
        return
      }

      // 这几个参数给dataFormatter用
      const echarts = $echarts
      const data = this.data
      const settings = {
        dimension: this.settings.dimension,
        metrics: this.settings.metrics,
        title: {
          title: this.settings && this.settings.title.title || '',
          color: (this.settings && this.settings.title.color) || (this.defaultSettings && this.defaultSettings.titleColor) || '#000',
          fontSize: (this.settings && this.settings.title.fontSize) || (this.defaultSettings && this.defaultSettings.titleFontSize) || 18
        },
        text: {
          color: (this.settings && this.settings.text.color) || (this.defaultSettings && this.defaultSettings.textColor) || '#000',
          fontSize: (this.settings && this.settings.text.fontSize) || (this.defaultSettings && this.defaultSettings.textFontSize) || 12
        }
      }
      const option = eval('(' + this.settings.dataFormatter + ')(data, settings)')
      //console.log(option)
      if (option) {
        if (this.mapStack.length > 0) {
          const oldOption = this.mapStack[this.mapStack.length - 1].option // JSON.parse(JSON.stringify(this.mapStack[this.mapStack.length - 1].option))
          const oldName = oldOption.geo.map
          for (let key in option) {
            oldOption[key] = option[key]
          }
          oldOption.geo.map = oldName
          if (option.geo && oldOption.geo) {
            if (option.geo.regions) {
              let lastRegions = option.geo.regions
              for (let i = 0; i < this.mapStack.length; i++) {
                const o = this.mapStack[i].option
                if (i > 0) {
                  let lr = undefined
                  for (let j = 0; j < lastRegions.length; j++) {
                    const r = lastRegions[j]
                    if (r.name == o.geo.map && r.regions) {
                      lr = r.regions
                      break
                    }
                  }
                  if (lr) {
                    lastRegions = lr
                  } else {
                    break
                  }
                }
              }
              oldOption.geo.regions = lastRegions
            }
          }
          this.echarts.setOption(JSON.parse(JSON.stringify(oldOption)), true);
        } else {
          this.loadMapIfNeeded(option, () => {
            if (this.geoJson) {
              this.mapStack = [{
                name: '',
                geoJson: this.geoJson,
                option: option
              }]
            }
            this.echarts.setOption(option, true);
          });
        }
      }
    },
    handleBack() {
      if (this.mapStack.length <= 1) {
        return
      }
      window.postMessage({
        type: 'bi_param_set',
        key: (this.settings && this.settings.event && this.settings.event.prefix || '') + 'name' + (this.mapStack.length - 1),
        value: ''
      })
      this.mapStack.splice(this.mapStack.length - 1, 1)
      const ms = this.mapStack[this.mapStack.length - 1]
      this.echarts.clear()
      this.echarts.setOption(ms.option, true)

      window.postMessage({
        type: 'bi_param_set',
        key: (this.settings && this.settings.event && this.settings.event.prefix || '') + 'name',
        value: ms.name
      })
      for (let i = 0; i < this.mapStack.length; i++) {
        const ms = this.mapStack[i]
        window.postMessage({
          type: 'bi_param_set',
          key: (this.settings && this.settings.event && this.settings.event.prefix || '') + 'name' + i,
          value: ms.name
        })
      }
    }
  },
  computed: {
    backButtonStyle() {
      let style = ''
      if (this.settings.text) {
        if (this.settings.text.color) {
          style += 'color: ' + this.settings.text.color + ';'
        } else if (this.defaultSettings && this.defaultSettings.textColor) {
          style += 'color: ' + this.defaultSettings.textColor + ';'
        }
        if (this.settings.text.fontSize) {
          style += 'font-size: ' + this.settings.text.fontSize + 'px;'
        } else if (this.defaultSettings && this.defaultSettings.textFontSize) {
          style += 'font-size: ' + this.defaultSettings.textFontSize + 'px;'
        }
      }
      style += 'right: ' + (this.settings.padding && this.settings.padding.right || 0) + 'px;'
      style += 'top: ' + (this.settings.padding && this.settings.padding.top || 0) + 'px;'
      return style
    }
  },
  watch: {
    data() {
      this.setOption()
    },
    settings() {
      this.setOption()
    },
    defaultSettings() {
      this.setOption()
    }
  }
}
</script>

<style scoped>
.back-button {
  position: absolute;
  color: white;
}
.back-button:hover {
  cursor: pointer;
}
</style>