<template>
  <div ref="container" :style="textStyle" :class="settings && settings.event.pointerEvent && (settings.event.naviUrl || type == 'select') && readonly ? 'button' : ''" @click="handleClick">
    <img v-if="settings.prefix && settings.prefix.image" :src="settings.prefix.image" :style="prefixImageStyle" />
    <i v-if="settings.prefix && settings.prefix.icon && settings.prefix.icon.indexOf('el-icon-') === 0" :class="'el-icon ' +settings.prefix.icon" :style="prefixIconStyle" />
    <i v-else-if="settings.prefix && settings.prefix.icon && settings.prefix.icon.indexOf('fa fa-') === 0" :class="settings.prefix.icon" :style="prefixIconStyle" />
    <svg-icon v-else-if="settings.prefix && settings.prefix.icon"
      :icon-class="settings.prefix.icon"
      :style="prefixIconStyle"
    />
    <div :style="textSubStyle">
      <span v-if="settings.prefix && settings.prefix.text" :style="prefixTextStyle">{{settings.prefix.text}}</span>
      <span>
        <span v-html="unitValue" :title="tipText" :style="unitValueStyle"></span>
        <span v-if="settings.unit && settings.unit.text" :style="unitTextStyle">{{unitText}}</span>
      </span>
      <span v-if="settings.suffix && settings.suffix.text" :style="suffixTextStyle">{{settings.suffix.text}}</span>
    </div>
    <i v-if="settings.prefix && settings.suffix.icon && settings.suffix.icon.indexOf('el-icon-') === 0" :class="'el-icon ' +settings.suffix.icon" :style="suffixIconStyle" />
    <i v-else-if="settings.prefix && settings.suffix.icon && settings.suffix.icon.indexOf('fa fa-') === 0" :class="settings.suffix.icon" :style="suffixIconStyle" />
    <svg-icon v-else-if="settings.prefix && settings.suffix.icon"
      :icon-class="settings.suffix.icon"
      :style="suffixIconStyle"
    />
    <img v-if="settings.suffix && settings.suffix.image" :src="settings.suffix.image" :style="suffixImageStyle" />
  </div>
</template>

<script>
import { parseValue } from '../utils/common'
export default {
  name: "widget-text",
  props: {
    type: {
      type: String,
      default: () => { return 'text' }
    },
    params: {
      type: Object,
      default: () => { return undefined }
    },
    data: {
      type: Array,
      default: () => { return undefined }
    },
    value: {
      type: String,
      default: () => { return '' }
    },
    settings: {
      type: Object,
      default: () => { return undefined }
    },
    defaultSettings: {
      type: Object,
      default: () => { return undefined }
    },
    readonly: {
      type: Boolean,
      default: () => { return true }
    }
  },
  data() {
    return {
      unitText: '',
      tipText: '',
      unitValue: '',
      selectedLabel: '',
      selectedValue: undefined
    }
  },
  created() {
    this.parseValue(true)
    /*let rt = Math.floor(Math.random()*10+5)
    let c = 0
    this.timerId1 = setInterval(() => {
      c += 1
      if (c > rt) {
        rt = Math.floor(Math.random()*10+5)
        c = 0
        this.animateValue()
      }
    }, 1000)*/
  },
  destroyed() {
    if (this.timerId) {
      clearInterval(this.timerId)
    }
  },
  methods: {
    setSelectedItem(item) {
      if (this.settings && this.settings.event && this.settings.event.prefix) {
        window.postMessage({
          type: 'bi_param_set',
          key: this.settings.event.prefix,
          value: item.value
        })
      }
      this.selectedLabel = item.label
      this.selectedValue = item.value
    },
    defaultValue() {
      if (!this.value) {
        return this.value
      }
      return parseValue(this.value, this.params, this.$route && this.$route.query)
    },
    getDataFormatterValue() {
      if (this.settings.dataFormatter) {
        const data = this.data
        const settings = {
          dimension: this.settings.dimension,
          metrics: this.settings.metrics
        }
        const ret = eval('(' + this.settings.dataFormatter + ')(data, settings)')
        if (ret !== undefined) {
          return ret
        }
      }
    },
    getDataValue() {
      const defaultValue = this.defaultValue()
      if (!this.data || this.data.length == 0 || !this.settings) {
        if (this.type == 'select') {
          if (this.selectedValue) {
            if (this.settings && this.settings.event && this.settings.event.prefix) {
              window.postMessage({
                type: 'bi_param_set',
                key: this.settings.event.prefix,
                value: ''
              })
            }
            this.selectedLabel = ''
            this.selectedValue = undefined
          }
          return ''
        } else {
          /*try {
            const dataFormatterValue = this.getDataFormatterValue()
            if (dataFormatterValue) {
              return dataFormatterValue
            }
          } catch(e) {
            console.error(e)
          }*/
          return defaultValue
        }
      }

      if (this.type == 'select') {
        if (this.selectedValue !== undefined || defaultValue) {
          if (this.settings.dimension && this.settings.dimension.length > 0 && this.settings.metrics && this.settings.metrics.length > 0) {
            const dim = this.settings.dimension[0]
            const met = this.settings.metrics[0]
            let selectedLabel = ''
            let value = undefined
            let label = ''
            for (let i = 0; i < this.data.length; i++) {
              const d = this.data[i]
              if (this.selectedValue !== undefined && d[met] == this.selectedValue) {
                selectedLabel = d[dim]
              }
              if (d[met] == defaultValue) {
                label = d[dim]
                value = d[met]
              }
            }

            if (selectedLabel) {
              return selectedLabel
            } else {
              if (this.selectedLabel) {
                if (this.settings && this.settings.event && this.settings.event.prefix) {
                  window.postMessage({
                    type: 'bi_param_set',
                    key: this.settings.event.prefix,
                    value: value
                  })
                }
                this.selectedLabel = label
                this.selectedValue = value
              }
              return label
            }
          }
          return ''
        } else {
          if (this.settings.dimension && this.settings.dimension.length > 0) {
            const dim = this.settings.dimension[0]
            return this.data[0][dim]
          } else {
            return ''
          }
        }
      } else {
        const dataFormatterValue = this.getDataFormatterValue()
        if (dataFormatterValue) {
          return dataFormatterValue
        }

        if (this.settings.metrics && this.settings.metrics.length > 0) {
          return this.data[0][this.settings.metrics[0]]
        } else {
          return defaultValue
        }
      }
    },
    parseValue(animate) {
      let value = this.getDataValue()
      if (value && typeof value == 'string') {
        value = parseValue(value, this.params, this.$route && this.$route.query)
      }

      let unitText = undefined
      let unitValue = undefined
      if (this.settings.unit && this.settings.unit.text) {
        const dv = parseFloat(value)
        let ut = this.settings.unit.text
        let lastU = 0
        let lastT = ''
        const sps1 = ut.split(' ')
        for (let i = 0; i < sps1.length; i++) {
          const sps2 = sps1[i].split('=')
          if (sps2.length == 2) {
            const u = parseFloat(sps2[0])
            if (u != 0) {
              if (dv / u < 1) {
                break
              }
              lastU = dv / u
              if (this.settings.unit.scale !== undefined) {
                lastU = lastU.toFixed(this.settings.unit.scale)
              }
              lastT = sps2[1]
            } else {
              lastU = dv
              if (this.settings.unit.scale !== undefined) {
                lastU = lastU.toFixed(this.settings.unit.scale)
              }
              lastT = sps2[1]
            }
          }
        }
        if (lastT) {
          unitText = lastT
          unitValue = lastU
        } else {
          unitText = ut
          unitValue = value
        }
      } else {
        unitText = ''
        unitValue = value
      }

      this.unitText = unitText
      if (typeof unitValue == 'string') {
        if (unitValue.indexOf('\n') >= 0) {
          this.unitValue = unitValue.replace(/\n/g, '<br/>')
        } else {
          this.unitValue = unitValue
        }
        if (this.readonly && this.settings.text && this.settings.text.showTip) {
          if (unitValue.indexOf('<br/>') >= 0) {
            this.tipText = unitValue.replace(/<br\/>/g, '\n')
          } else {
            this.tipText = unitValue
          }
        }
      } else {
        this.unitValue = unitValue
        if (this.readonly && this.settings.text && this.settings.text.showTip) {
          this.tipText = unitValue
        }
      }

      if (animate) {
        this.animateValue()
      }
    },
    animateValue() {
      if (this.settings.unit && this.settings.unit.animate && /^[0-9\.]+$/.test(this.unitValue)) {
        const timerDuration = 10
        const unitValue = parseFloat(this.unitValue)
        if (unitValue) {
          if (this.animatingValue && this.animatingValue == unitValue) {
            return
          }
          this.animatingValue = unitValue
          const step = unitValue / ((this.settings.unit.animateDuration || 1500) / timerDuration)
          let tv = 0
          if (this.timerId) {
            clearInterval(this.timerId)
          }
          this.timerId = setInterval(() => {
            tv += step
            if (tv > unitValue) {
              tv = unitValue
              clearInterval(this.timerId)
              this.timerId = undefined
              setTimeout(() => {
                this.animatingValue = undefined
              }, 1000)
            }
            if (this.settings.unit.scale !== undefined) {
              this.unitValue = tv.toFixed(this.settings.unit.scale)
            } else {
              this.unitValue = tv.toFixed(0)
            }
          }, timerDuration)
        }
      }
    },
    handleClick() {
      if (!this.settings || !this.settings.event.pointerEvent || !this.readonly) {
        return
      }
      if (this.type == 'select') {
        if (this.settings.dimension && this.settings.dimension.length > 0) {
          const data = []
          const dim = this.settings.dimension[0]
          let met = undefined
          if (this.settings.metrics && this.settings.metrics.length > 0) {
            met = this.settings.metrics[0]
          }
          for (let i = 0; i < this.data.length; i++) {
            const d = this.data[i]
            const dl = d[dim]
            const sd = {
              label: dl,
              value: dl
            }
            if (met) {
              const dv = d[met]
              if (dv !== undefined) {
                sd.value = dv
              }
            }
            data.push(sd)
          }
          this.$emit('message', 'trigger-select', data, this)
        }
      } else if (this.settings.event.naviUrl) {
        const naviUrl = parseValue(this.settings.event.naviUrl, this.params, this.$route && this.$route.query)
        this.$emit('message', 'open-url', this.settings.event.naviType, naviUrl, this)
      }
    }
  },
  computed: {
    textStyle() {
      let style = 'height: 100%; width: 100%; flex: 1; display: flex; overflow: auto;' // justify-content: center; align-items: center;

      if ((this.settings.prefix && this.settings.prefix.direction == 'vertical') || (this.settings.suffix && this.settings.suffix.direction == 'vertical')) {
        style += 'flex-direction: column;'
      }

      if (this.settings.text) {
        if (this.settings.text.color) {
          style += 'color: ' + this.settings.text.color + ';'
        } else if (this.defaultSettings && this.defaultSettings.textColor) {
          style += 'color: ' + this.defaultSettings.textColor + ';'
        }
        if (this.settings.text.fontSize) {
          style += 'font-size: ' + this.settings.text.fontSize + 'px;'
        } else if (this.defaultSettings && this.defaultSettings.textFontSize) {
          style += 'font-size: ' + this.defaultSettings.textFontSize + 'px;'
        }
        if (this.settings.text.bold) {
            style += 'font-weight: bold;'
        } else {
            style += 'font-weight: normal;'
        }
        if (this.settings.text.lineHeight) {
          style += 'line-height: ' + this.settings.text.lineHeight + 'px;'
        }
        if (this.settings.text.align == 'left') {
          style += 'justify-content: flex-start;'
        } else if (this.settings.text.align == 'right') {
          style += 'justify-content: flex-end;'
        } else {
          style += 'justify-content: center;'
        }
        if (this.settings.text.valign == 'top') {
          style += 'align-items: flex-start;'
        } else if (this.settings.text.valign == 'bottom') {
          style += 'align-items: flex-end;'
        } else {
          style += 'align-items: center;'
        }
      } else {
        if (this.defaultSettings && this.defaultSettings.textColor) {
          style += 'color: ' + this.defaultSettings.textColor + ';'
        }
        if (this.defaultSettings && this.defaultSettings.textFontSize) {
          style += 'font-size: ' + this.defaultSettings.textFontSize + 'px;'
        }
      }
      
      return style
    },
    textSubStyle() {
      let style = ''
      if ((this.settings.prefix && this.settings.prefix.direction == 'vertical') || (this.settings.suffix && this.settings.suffix.direction == 'vertical')) {
        style += 'display: flex; flex-direction: column; align-items: center;'
      } else {
        style += 'display: table-cell; vertical-align: bottom;'
        if (this.settings.text && this.settings.text.align == 'left') {
          style += 'text-align: left;'
        }
      }
      if (this.type == 'select') {
        style += 'width:0; flex:1; overflow: hidden;'
      }
      return style;
    },
    prefixImageStyle() {
      let style = ''
      if (this.settings.prefix) {
        if (this.settings.prefix.imageWidth) {
          style += 'width: ' + this.settings.prefix.imageWidth + 'px;'
        }
        if (this.settings.prefix.imageHeight) {
          style += 'height: ' + this.settings.prefix.imageHeight + 'px;'
        }
        if (this.settings.prefix.imageMargin) {
          if ((this.settings.prefix && this.settings.prefix.direction == 'vertical') || (this.settings.suffix && this.settings.suffix.direction == 'vertical')) {
            style += 'margin-bottom: ' + this.settings.prefix.imageMargin + 'px;'
          } else {
            style += 'margin-right: ' + this.settings.prefix.imageMargin + 'px;'
          }
        }
      }
      return style
    },
    suffixImageStyle() {
      let style = ''
      if (this.settings.suffix) {
        if (this.settings.suffix.imageWidth) {
          style += 'width: ' + this.settings.suffix.imageWidth + 'px;'
        }
        if (this.settings.suffix.imageHeight) {
          style += 'height: ' + this.settings.suffix.imageHeight + 'px;'
        }
        if (this.settings.suffix.imageMargin) {
          if ((this.settings.prefix && this.settings.prefix.direction == 'vertical') || (this.settings.suffix && this.settings.suffix.direction == 'vertical')) {
            style += 'margin-top: ' + this.settings.suffix.imageMargin + 'px;'
          } else {
            style += 'margin-left: ' + this.settings.suffix.imageMargin + 'px;'
          }
        }
      }
      return style
    },
    prefixIconStyle() {
      let style = ''
      if (this.settings.prefix) {
        if (this.settings.prefix.iconColor) {
          style += 'color: ' + this.settings.prefix.iconColor + ';'
        }
        if (this.settings.prefix.iconSize) {
          style += 'font-size: ' + this.settings.prefix.iconSize + 'px;'
        }
        if (this.settings.prefix.iconMargin) {
          if ((this.settings.prefix && this.settings.prefix.direction == 'vertical') || (this.settings.suffix && this.settings.suffix.direction == 'vertical')) {
            style += 'margin-bottom: ' + this.settings.prefix.iconMargin + 'px;'
          } else {
            style += 'margin-right: ' + this.settings.prefix.iconMargin + 'px;'
          }
        }
      }
      return style
    },
    suffixIconStyle() {
      let style = ''
      if (this.settings.suffix) {
        if (this.settings.suffix.iconColor) {
          style += 'color: ' + this.settings.suffix.iconColor + ';'
        }
        if (this.settings.suffix.iconSize) {
          style += 'font-size: ' + this.settings.suffix.iconSize + 'px;'
        }
        if (this.settings.suffix.iconMargin) {
          if ((this.settings.prefix && this.settings.prefix.direction == 'vertical') || (this.settings.suffix && this.settings.suffix.direction == 'vertical')) {
            style += 'margin-top: ' + this.settings.suffix.iconMargin + 'px;'
          } else {
            style += 'margin-left: ' + this.settings.suffix.iconMargin + 'px;'
          }
        }
      }
      return style
    },
    prefixTextStyle() {
      let style = ''
      if (this.settings.prefix) {
        if (this.settings.prefix.textColor) {
          style += 'color: ' + this.settings.prefix.textColor + ';'
        }
        if (this.settings.prefix.fontSize) {
          style += 'font-size: ' + this.settings.prefix.fontSize + 'px;'
        }
        if (this.settings.prefix.bold) {
            style += 'font-weight: bold;'
        } else {
            style += 'font-weight: normal;'
        }
        if (this.settings.prefix.textMargin) {
          if ((this.settings.prefix && this.settings.prefix.direction == 'vertical') || (this.settings.suffix && this.settings.suffix.direction == 'vertical')) {
            style += 'margin-bottom: ' + this.settings.prefix.textMargin + 'px;'
          } else {
            style += 'margin-right: ' + this.settings.prefix.textMargin + 'px;'
          }
        }
      }
      return style
    },
    suffixTextStyle() {
      let style = ''
      if (this.settings.suffix) {
        if (this.settings.suffix.textColor) {
          style += 'color: ' + this.settings.suffix.textColor + ';'
        }
        if (this.settings.suffix.fontSize) {
          style += 'font-size: ' + this.settings.suffix.fontSize + 'px;'
        }
        if (this.settings.suffix.bold) {
            style += 'font-weight: bold;'
        } else {
            style += 'font-weight: normal;'
        }
        if (this.settings.suffix.textMargin) {
          if ((this.settings.prefix && this.settings.prefix.direction == 'vertical') || (this.settings.suffix && this.settings.suffix.direction == 'vertical')) {
            style += 'margin-top: ' + this.settings.suffix.textMargin + 'px;'
          } else {
            style += 'margin-left: ' + this.settings.suffix.textMargin + 'px;'
          }
        }
      }
      return style
    },
    unitValueStyle() {
      let style = ''
      if (this.type == 'select') {
        style = 'white-space: nowrap;'
      } else {
        style = 'word-break: break-all;'
      }
      return style
    },
    unitTextStyle() {
      let style = ''
      if (this.settings.unit) {
        if (this.settings.unit.textColor) {
          style += 'color: ' + this.settings.unit.textColor + ';'
        }
        if (this.settings.unit.fontSize) {
          style += 'font-size: ' + this.settings.unit.fontSize + 'px;'
        }
        if (this.settings.unit.bold) {
            style += 'font-weight: bold;'
        } else {
            style += 'font-weight: normal;'
        }
        if (this.settings.unit.textMargin) {
          style += 'margin-left: ' + this.settings.unit.textMargin + 'px;'
        }
      }
      return style
    }
  },
  watch: {
    data() {
      this.parseValue(true)
    },
    settings() {
      this.parseValue(true)
    },
    value() {
      this.parseValue(true)
    },
    selectedValue() {
      this.parseValue(true)
    },
    params() {
      this.parseValue()
    }
  }
}
</script>

<style scoped>
.button:hover {
  cursor: pointer;
}
::-webkit-scrollbar {
  width:0px;
}
</style>