import request from '@/utils/request'

export function listBiDatascreenMap(query) {
  return request({
    url: '/bi/bidatascreenmap/list',
    method: 'get',
    params: query
  })
}

export function getBiDatascreenMap(id) {
  return request({
    url: '/bi/bidatascreenmap/get?id=' + id,
    method: 'get'
  })
}

export function addBiDatascreenMap(data) {
  return request({
    url: '/bi/bidatascreenmap/add',
    method: 'post',
    data: data
  })
}

export function editBiDatascreenMap(data) {
  return request({
    url: '/bi/bidatascreenmap/edit',
    method: 'post',
    data: data
  })
}

export function delBiDatascreenMap(ids) {
  return request({
    url: '/bi/bidatascreenmap/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

